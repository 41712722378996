.iconBtn {
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconBtn span {
  font-size: 20;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.langIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
