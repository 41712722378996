.container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 8px;
  gap: 6px;
}

.button {
  border-radius: 20px 20px 8px 20px !important;
  border-color: #0a4dce !important;
  color: #0a4dce !important;
  width: fit-content;
  text-transform: none !important;
}