.root {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 30px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: rgba(0, 0, 0, 0.54);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 10px 0 -1.3em;
  }
  40% {
    box-shadow: 0 10px 0 0;
  }
}
