.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  max-width: 360px;
  margin: 0 auto;
}

.error {
  color: red;
  font-size: 13px;
  text-align: center;
}

.button {
  height: 56px;
  border-radius: 100px !important;
  background-color: #0A4DCE !important;
  text-transform: unset !important;
}

.selectLang {
  position: absolute;
  top: 24px;
  right: 24px;
}

.input input{
  background-color: #FFFFFF;
}