.inputContainer {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 56px;
  align-items: center;
  gap: 16px;
  background-color: #ffffff;
}

.input > div {
  border-radius: 32px;
  background-color: #F3F6FD;
}

.input fieldset {
  border: none;
}

.iconBtn {
  width: 56px;
  height: 56px;
  background-color: #0A4DCE !important;
}