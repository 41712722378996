.messageContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.responseMessage {
  margin-right: auto;
  margin-left: unset !important;
  border-radius: 20px 20px 20px 8px;
  background-color: #f3f6fd;
  color: #212121;
}

.responseMessage .messageTime {
  color: #49454f99;
}

.requestMessage {
  border-radius: 20px 20px 8px 20px;
  background-color: #0a4dce;
  color: #ffffff;
}

.messageContainer > div {
  margin-left: auto;
}

.textMessage {
  padding: 10px;
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: flex-end;
  row-gap: 2px;
  column-gap: 8px;
  font-size: 16px;
}

.textMessage > div {
  word-wrap: break-word;
}

.messageTime {
  font-size: 12px;
  color: #ffffff99;
}

.imageMessage {
  max-width: 80%;
  border-radius: 20px;
}

.audioMessage {
  max-width: 80%;
  border-radius: 20px;
}
