.preLoader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00b0f0;
  z-index: 10;

  animation-name: hidePreLogo;
  animation-duration: 0.5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes hidePreLogo {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    z-index: -10;
  }
}

.preLoader img {
  transition: 1s;
  animation-name: upscaleImage;
  animation-duration: 1.25s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes upscaleImage {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(3.5);
  }
}
